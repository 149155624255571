import { useCallback, useEffect, useState } from "react";
import ArrowRightMd from "../../icons/ArrowRightMd";
import styles from "./RedeemBalance.module.css";
import {
  useAccount,
  useBalance,
  useWaitForTransactionReceipt,
  useWriteContract,
} from "wagmi";
import {
  Allowance,
  ApproveDollar,
  DecimalValue,
  FloorPrice,
  MaxFeePercent,
  TokenBalance,
  redeemToken,
} from "../../web3/Contracts/TokenMarketPlace";
import { parseUnits } from "viem";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button, Form, Input } from "antd";
import { useForm } from "antd/es/form/Form";
import { useSelector } from "react-redux";
import {
  useGetFloorPriceQuery,
  useGetMinRedeemBalanceQuery,
  useGetPortfolioValueQuery,
  useGetWalletBalanceQuery,
} from "../../services/Auth";
import { config } from "../../config/config";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import ERC20Abi from "../../web3/ABI/ERC20.json";

const StyledButton = styled(Button)`
  &:hover {
    background-color: transparent !important;
    border: none !important;
    span {
      color: #0058a0 !important;
    }
  }
`;

const StyledInput = styled(Input)`
  border: none !important;
  background: white;
  padding-right: 10px !important;
  box-shadow: none !important;

  & > input {
    font-weight: bold;
  }
  & > input:focus {
    box-shadow: none !important;
  }
`;

const RedeemBalance = () => {
  const [activeClass, setActiveClass] = useState(styles.mouseLeave);
  const { address, chainId } = useAccount();

  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const { writeContractAsync, data, isError, error } = useWriteContract();
  const navigate = useNavigate();
  const waitMetod = useWaitForTransactionReceipt({
    confirmations: 1,
    hash: `0x${data?.slice(2)}`,
  });

  const [balances, setBalances] = useState<{
    hedgePrice: any;
    usdcPrice: any;
    binancePrice: any;
  }>({
    hedgePrice: 0,
    usdcPrice: 0,
    binancePrice: 0,
  });
  const selectedConfig = useSelector(
    (state: any) => state.global.selectedConfig
  );
  const blockChainNetwork = useSelector((state: any) => state.global.network);
  const { data: getFloorPrice } = useGetFloorPriceQuery({
    network: blockChainNetwork.toUpperCase(),
  });
  const owner = address;
  const contractAddress = selectedConfig.Hedge_Token_Address;
  const [decimals, setDecimals] = useState<any>({
    dollar: DecimalValue(selectedConfig.Dollar_Token_Address)?.decimalValue,
    hedge: DecimalValue(selectedConfig.Hedge_Token_Address)?.decimalValue,
  });
  const { data: getPortfolioValue } = useGetPortfolioValueQuery({
    network: blockChainNetwork.toUpperCase(),
    address: address,
  });

  const [check, setCheck] = useState(false);
  const { data: getWalletBalance } = useGetWalletBalanceQuery({
    address: address,
  });
  const [maxFee, setMaxFee] = useState(0);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [approveBuy, setApproveBuy] = useState(0);
  const [formValues, setFormValues] = useState<any>({
    UsdtValue: "",
    HdgValue: "",
  });
  const [isApproved, setIsApproved] = useState(false);
  const [isRedeem, setIsRedeem] = useState(false);
  const { data: getMinRedeemBalance } = useGetMinRedeemBalanceQuery({
    network: blockChainNetwork,
  });
  const [value, setValue] = useState("");
  const [allowanceCheck, setAllowanceCheck] = useState(false);
  const [AllowanceAmount, setAllowanceAmount] = useState(
    Allowance(contractAddress, owner, selectedConfig)?.allowance /
      10 ** decimals
  );
  let refetchAllowance = Allowance(
    contractAddress,
    owner,
    selectedConfig
  )?.refetchAllowance;
  let refetchDollarDecimals = DecimalValue(
    selectedConfig.Dollar_Token_Address
  )?.refetchDecimals;
  let refetchHedgeDecimals = DecimalValue(
    selectedConfig.Hedge_Token_Address
  )?.refetchDecimals;

  const getAllowanceValue = useCallback(async () => {
    let response = await refetchAllowance();
    return Promise.resolve(Number(response?.data));
  }, [refetchAllowance]);

  const getDecimalValue = useCallback(async () => {
    try {
      let responseDollar = await refetchDollarDecimals();
      let responseHedge = await refetchHedgeDecimals();
      return {
        dollarDecimals: Promise.resolve(responseDollar),
        hedgeDecimals: Promise.resolve(responseHedge),
      };
    } catch (err) {
      console.log(err);
      toast.error("something went wrong");
    }
  }, [refetchHedgeDecimals, refetchDollarDecimals, selectedConfig]);

  useEffect(() => {
    getAllowanceValue().then((data) => {
      setAllowanceAmount(Number(data) / 10 ** decimals?.hedge);
    });
  }, [getAllowanceValue, selectedConfig]);

  useEffect(() => {
    getDecimalValue().then((data) => {
      data?.dollarDecimals?.then((dollar) => {
        setDecimals((prev: any) => {
          return {
            ...prev,
            dollar: dollar?.data,
          };
        });
      });
      data?.hedgeDecimals?.then((hedge) => {
        setDecimals((prev: any) => {
          return {
            ...prev,
            hedge: hedge?.data,
          };
        });
      });
    });
  }, [getDecimalValue, selectedConfig]);

  useEffect(() => {
    let timer = setInterval(() => {
      getAllowanceValue().then((data) => {
        setAllowanceAmount(Number(data) / 10 ** decimals?.hedge);
      });
    }, 2000);
    return () => clearInterval(timer);
  }, [getAllowanceValue]);

  useEffect(() => {
    if (waitMetod.isSuccess && isApproved) {
      toast.success("Token Approved successfully");
      setAllowanceCheck(false);
      setLoading(false);
    } else if (waitMetod.isSuccess && isRedeem) {
      toast.success("Token Redeemed successfully");
      setLoading(false);
      setIsRedeem(false);
    }
  }, [waitMetod.isSuccess, isApproved, isRedeem]);

  const maxFeePercent = MaxFeePercent(selectedConfig);
  const getMaxFee = (hdgExchange: any) => {
    if (blockChainNetwork === "Ethereum") {
      const fee = Number(
        (
          hdgExchange *
          getFloorPrice?.data *
          (Number(maxFeePercent) / 100)
        ).toFixed(2)
      );
      return fee;
    } else {
      const fee = Number(
        (
          hdgExchange *
          getFloorPrice?.data *
          (Number(maxFeePercent) / 100)
        ).toFixed(2)
      );
      return fee;
    }
  };
  const handleChange = (e: any) => {
    const hdgExchange = e.target.value;
    setApproveBuy(hdgExchange);
    let maxFeeValue = getMaxFee(hdgExchange);
    setMaxFee(maxFeeValue);
    form.setFieldValue(
      "UsdtValue",
      hdgExchange * getFloorPrice?.data - maxFeeValue
    );
    if (AllowanceAmount >= hdgExchange) {
      setCheck(true);
    } else {
      setCheck(false);
    }
  };
  useEffect(() => {
    form.setFieldValue("UsdtValue", 0);
    form.setFieldValue("HdgValue", 0);
  }, []);

  const getPrices = async () => {
    await refetchHedgePrice();
    await refetchUsdcBalance();
  };
  useEffect(() => {
    let interval = setInterval(() => {
      getPrices();
    }, 15000);
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    setBalances((prev) => {
      return {
        ...prev,
        hedgePrice,
        usdcPrice,
        binancePrice,
      };
    });
  }, [chainId, selectedConfig]);
  useEffect(() => {
    if (isError && error) {
      console.log(error?.message);
      const message = error?.message?.split(":");
      const messageEnd = message?.[1]?.split("\n");
      toast.error(
        "Transaction reverted due to: " +
          message?.[1]?.slice(0, messageEnd?.[1]?.length + 1) +
          " or User rejected the transaction!!"
      );
      setLoading(false);
      setIsRedeem(false);
    }
  }, [error, isError]);

  let { refetch: refetchHedgePrice, value: hedgePrice } = TokenBalance({
    tokenAddress: selectedConfig.Hedge_Token_Address,
    userAddress: address,
  });

  let { refetch: refetchUsdcBalance, value: usdcPrice } = TokenBalance({
    tokenAddress: selectedConfig.Dollar_Token_Address,
    userAddress: address,
  });

  let binancePrice = useBalance({
    address: address,
  });

  let currencyList = [
    {
      label: "HEDGE Compass",
      ticker: "HDG",
      price: (Number(hedgePrice) / 10 ** decimals?.hedge).toFixed(3),
      marketPercent: "+2.6",
      icon: "hedge-icon.svg",
      address: selectedConfig.Hedge_Token_Address,
    },
    {
      label: "Tether",
      ticker: "USDT",
      price: (Number(usdcPrice) / 10 ** decimals?.dollar).toFixed(3),
      marketPercent: "-1.66",
      icon: "tether_icon.svg",
      address: selectedConfig.Hedge_Token_Address,
    },
    {
      label: "Binance",
      ticker: "BNB",
      price: Number(getWalletBalance?.data?.bnbBalance).toFixed(3) || "-",
      marketPercent: "-21.66",
      icon: "binance-icon.svg",
      address: selectedConfig.Hedge_Token_Address,
    },
    {
      label: "Ethereum",
      ticker: "ETH",
      price: Number(getWalletBalance?.data?.ethBalance).toFixed(3) || "-",
      marketPercent: "-21.66",
      icon: "ethereum-icon.svg",
      address: selectedConfig.Hedge_Token_Address,
    },
  ];

  const handleRedeem = async (values: { HdgValue: any; UsdtValue: any }) => {
    try {
      setLoading(true);
      setIsRedeem(false);
      if (AllowanceAmount >= values?.HdgValue) {
        const amount = parseUnits(values.HdgValue, decimals?.hedge);
        const result = await redeemToken(
          writeContractAsync,
          amount,
          address,
          selectedConfig,
          setLoading,
          setOpenConfirmationModal
        );
        refetchHedgePrice();
        refetchUsdcBalance();
        form.resetFields();
        setIsApproved(false);
        setIsRedeem(true);
      } else {
        setLoading(true);
        setIsApproved(false);
        setAllowanceCheck(true);
        const amount = Number(config?.MAX_APPROVAL) * 10 ** decimals?.hedge;
        const tokenAddress = selectedConfig.Hedge_Token_Address;
        const response = await ApproveDollar(
          writeContractAsync,
          amount,
          address,
          tokenAddress,
          selectedConfig,
          setLoading,
          setOpenConfirmationModal,
          ERC20Abi
        );

        setFormValues(values);
        setIsApproved(true);
        setValue(values?.HdgValue);
        setOpenConfirmationModal(true);
      }
    } catch (err) {
      setLoading(false);
      setIsApproved(false);
      setOpenConfirmationModal(false);
      setIsRedeem(false);
      form.resetFields();
      setMaxFee(0)
    }
  };

  const onModalRedeem = async () => {
    setLoading(true);
    setIsRedeem(false);
    const amount2 = Number(formValues?.HdgValue) * 10 ** decimals?.hedge;
    try {
      const result = await redeemToken(
        writeContractAsync,
        amount2,
        address,
        selectedConfig
      );

      refetchHedgePrice();
      refetchUsdcBalance();
      form.resetFields();
      setIsRedeem(true);
      setIsApproved(false);
      setOpenConfirmationModal(false);
      setLoading(false);
      setAllowanceCheck(false);
    } catch (err) {
      setLoading(false);
      setIsRedeem(false);
      setAllowanceCheck(false);
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault();
    }
  };
  
  return (
    <section className={styles.wrapper}>
      <div>
        <div className={styles.cardsWrapper}>
          <div className={styles.innerWrapper}>
            <div className={styles.buttonsGroup}>
              <button
                onClick={() => navigate("/buy-balance")}
                className={styles.buyButton}
              >
                <span>Buy</span>
              </button>
              <button
                onClick={() => navigate("/redeem-balance")}
                className={styles.redeemButton}
              >
                <span>Redeem</span>
              </button>
            </div>

            <div className={styles.header}>
              <div className={styles.headerTitle}>Account 1</div>
              <div className={styles.wallet}>
                <label className={styles.walletLabel}>{address}</label>
                <div className={styles.walletButton}>
                  <img src="/copy-icon.svg" alt="copy_icon" />
                  <button
                    className={styles.copyLabel}
                    onClick={() => {
                      navigator.clipboard.writeText(address?.toString() || "");
                      toast.success("Copied.!");
                    }}
                  >
                    Copy
                  </button>
                </div>
              </div>
            </div>

            <div className={styles.subHeader}>
              <div className={styles.subHeaderTitle}>Current Balance</div>
              <div className={styles.divider} />
            </div>

            <div className={styles.portfolio}>
              <label className={styles.portfolioLabel}>Portfolio Balance</label>
              <div className={styles.portfolioAmount}>
                <div className={styles.amount}>
                  ${" "}
                  {getPortfolioValue?.data &&
                    Number(getPortfolioValue?.data)?.toFixed(3)}
                </div>
              </div>
            </div>

            <div className={styles.currencyWrapper}>
              {currencyList.map((currency, i) => (
                <div key={i} className={styles.currencyCard}>
                  <div className={styles.currencyIcon}>
                    <img
                      width={"24px"}
                      src={`/${currency.icon}`}
                      alt="currency_icon"
                    />
                  </div>
                  <div className={styles.ticker}>
                    <div className={styles.currency}>
                      <div className={styles.currencyName}>
                        {currency.label}
                      </div>
                      <div className={styles.currencyTicker}>
                        {currency.ticker}
                      </div>
                    </div>
                    <div className={styles.price}>
                      <div className={styles.tooltip}>
                        <div className={styles.currencyPrice}>
                          {currency.price}
                        </div>
                        <span className={styles.tooltiptext}>
                          {currency.price}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <Form
              form={form}
              onFinish={handleRedeem}
              className={styles.amountWrapper}
            >
              <div className={styles.amountTitle}>
                <label className={styles.label}>Amount</label>
                <label className={styles.label}>
                  Fee:
                  <span>{maxFee} USDT</span>{" "}
                </label>
              </div>
              <div className={styles.convertWrapper}>
                <Form.Item
                  style={{
                    marginBottom: "0",
                    width: "100%",
                    borderRadius: "12px",
                    paddingRight: "10px",
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Required!",
                    },

                    ({}) => ({
                      validator(_, value) {
                        if (!(+getMinRedeemBalance?.data >= +value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            `Please enter value more than ${getMinRedeemBalance?.data} minimum redeem amount`
                          )
                        );
                      },
                    }),
                  ]}
                  name="HdgValue"
                >
                  <StyledInput
                    className={styles.inputField}
                    type="number"
                    suffix="HDG"
                    placeholder="0"
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    onWheel={(event) => event.currentTarget.blur()}
                  />
                </Form.Item>
                <div className={styles.convert}>
                  <img
                    style={{ width: "22px" }}
                    src="/convert-gray-icon.svg"
                    alt="convert_icon"
                  />
                </div>
                <Form.Item
                  style={{
                    marginBottom: "0",
                    width: "100%",
                    borderRadius: "12px",
                    paddingRight: "10px",
                  }}
                  name="UsdtValue"
                >
                  <StyledInput
                    className={styles.inputField}
                    type="number"
                    suffix="USDT"
                    placeholder="0"
                    readOnly
                  />
                </Form.Item>
              </div>

              <StyledButton
                className={`${styles.button} ${activeClass}`}
                onMouseEnter={() => setActiveClass(styles.mouseEnter)}
                onMouseLeave={() => setActiveClass(styles.mouseLeave)}
                htmlType="submit"
                loading={loading || isRedeem}
                disabled={loading}
              >
                {loading || isRedeem ? (
                  <span>Pending</span>
                ) : (
                  <span>Redeem HEDGE Compass</span>
                )}
                <ArrowRightMd />
              </StyledButton>
            </Form>
          </div>
        </div>
      </div>
      <ConfirmationModal
        setOpenConfirmationModal={setOpenConfirmationModal}
        openConfirmationModal={openConfirmationModal}
        approveBuyAmount={approveBuy}
        btnAction={onModalRedeem}
        allowanceAmount={AllowanceAmount}
        loading={loading}
        isApproved={isApproved}
        value={value}
        allowanceCheck={allowanceCheck}
      />
    </section>
  );
};

export default RedeemBalance;
