import { useState } from 'react';
import Link from '../../components/Link/Link';
import NewsCard from '../../components/NewsCard/NewsCard';
import ArrowRightMd from '../../icons/ArrowRightMd';
import styles from './News.module.css';

import moment from 'moment';
import articles from '../../api/articles.json';
import { useSubscribeEmailMutation } from '../../services/Auth';
import toast from 'react-hot-toast';
import { Button } from 'antd';
import { StyledButton } from '../../components/ConfirmationModal/ConfirmationModal';
import styled from 'styled-components';

const News = () => {
  const [activeClass, setActiveClass] = useState(styles.mouseLeave);
  const [subscribeEmail, {isLoading}] = useSubscribeEmailMutation();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const handleChange = (e: any)=>{
    const check=validateEmail(e.target.value)
    if(!check){
      setError("Please enter a valid email address");
    }else{
      setError("");
    }
    setEmail(e.target.value);

  }
  function validateEmail(email: any) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
}

  articles.sort((a, b) => moment(b.date).diff(moment(a.date)));
  
  const handleSubmit=async(e:any)=>{
    e.preventDefault();
    try{
      const response = await subscribeEmail({email:email}).unwrap();
      setEmail("");
      toast.success(response?.data);

    }catch(err){
      toast.error("Please try again!");
    }
  }
 const StyledButton = styled(Button)`
  height: 45px;
  background: white !important;
  color:#0f3f8d !important;
  span {
    font-size: 20px;
  }
  &:hover{
    background: none !important;
  }
  
`;
  return (
    <section>
      <div className={styles.gradientWrapper}>
        <div className={styles.wrapper}>
          <p className={styles.breadcrumbs}>Home &gt; News</p>
          <div className={styles.imgBlock}>
            <img
              className={styles.img}
              src={articles[0].labelImage}
              alt='News img'
            />
            <div className={styles.textBlock}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '34px',
                }}>
                <span className={styles.smallText}>
                  {moment(articles[0].date).format('DD MMMM YYYY')}
                </span>
                <span className={styles.smallText}>{articles[0].readTime}</span>
              </div>
              <h3 className={styles.imgHeading}>{articles[0].header}</h3>
              <p className={styles.paragraph}>{articles[0].desc}</p>
              <Link
                to={`/news/article/${articles[0].id}`}
                variant='secondary'
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.bottomWrapper}>
        <h3 className={styles.heading}>See our latest news articles</h3>
        <div className={styles.newsList}>
          {articles.slice(1).map((item) => (
            <NewsCard
              key={item.id}
              src={item.labelImage}
              to={`/news/article/${item.id}`}
              title={item.header}
              text={item.desc}
            />
          ))}
        </div>
        <form className={styles.form} onSubmit={handleSubmit}>
          <h2>Stay up to date with all the news</h2>
          <div className={styles.inpurtWrapper}>
            <div style={{width: "100%"}}>

            <input placeholder='Email' autoComplete='false' value={email} onChange={handleChange}/>
            {error&& <span style={{color: "red"}}>{error}</span> }
            </div>
            <StyledButton
              className={`${styles.button} ${activeClass}`}
              onMouseEnter={() => setActiveClass(styles.mouseEnter)}
              onMouseLeave={() => setActiveClass(styles.mouseLeave)}
              htmlType='submit'
              loading={isLoading}
              disabled={isLoading || email.length==0 || error.length!=0}
              >
              <span>Subscribe</span>
            </StyledButton>
          </div>
        </form>
      </div>
    </section>
  );
};

export default News;
