import { Link, useParams } from 'react-router-dom';
import styles from './Article.module.css';
import articles from '../../api/articles.json';

const Article = () => {
  const { id } = useParams<{ id: string }>();
  const article = articles.find((article) => article.id === +id!);

  return (
    <section>
      <div className={styles.wrapper}>
        <div className={styles.breadcrumbs}>
          Home &gt; News &gt; {article?.header}
        </div>
        <div className={styles.imgContainer}>
          <img
            className={styles.img}
            src={article?.labelImage}
            alt='img'
          />
          <span className={styles.articleDate}>{article?.date}</span>
        </div>
      </div>
      <div className={styles.innerWrapper}>
        <div style={{ margin: '0 auto' }}>
          <h2 className={styles.heading}>{article?.header}</h2>
          {article?.intro1.map((text, index) => (
            <p key={index} className={styles.subHeading}>{text}</p>
          ))}
          {article?.intro2.map((text, index) => (
            <p key={index} className={styles.subHeading}>{text}</p>
          ))}
          <span className={styles.by}>By {article?.by}</span>
          {article?.headings.map((heading, index) => (
            <div key={index}>
              <h6 className={styles.smallHeading}>{heading.heading}</h6>
              {heading?.texts.map((text, index) => (
                <p key={index} className={styles.text}>{text}</p>
              ))}
            </div>
          ))}
          <Link className={styles.link} to='/disclaimer'>
            Terms and Conditions
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Article;
