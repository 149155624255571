import { useMediaQuery } from 'react-responsive';
import styles from './Arbitrage.module.css';
const Arbitrage = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return (
    <section>
      <div className={styles.colorWrapper}>
        <div className={styles.breadcrumbs}>Home &gt; Coins &gt; Arbitrage</div>
        <h2 className={styles.heading}>COMING SOON</h2>
        <img className={styles.img} src='/arbitrage-page.png' alt='compass' />
        <h3 className={styles.subHeading}>The power of ARBITRAGE!</h3>
      </div>
      <div className={styles.innerWrapper}>
        <div className={styles.card}>
          <img src='/arbitrage.gif' alt='compass' />
          <div style={{ maxWidth: '500px' }}>
            <p className={styles.cardTitle}>
              Introducing HEDGE Arbitrage: the second game-changing HEDGE token
              poised to revolutionize the world of cryptocurrencies
            </p>
            <div className={styles.border} />
            <p className={styles.cardText}>
              Further breaking down barriers, HEDGE Arbitrage continues to
              bridge the gap between cryptocurrency users and the exclusive
              world of hedge funds traditionally reserved for the select few.
            </p>
            <div className={styles.border} />
            <p className={styles.cardText}>
              HEDGE Arbitrage leverages the strategies of “arbitrage" hedge
              funds profiting from price differentials across multiple markets
              from across the globe to empower users with the potential for
              superior performance.
            </p>
            <div className={styles.border} />
            <p className={styles.cardText}>
              Get ready to redefine your crypto journey and experience the power
              of hedge funds like never before – simple, inclusive, and
              transformative.
            </p>
          </div>
        </div>
        <p className={styles.howItWorks}>How it works</p>
        <h3 className={styles.howItWorksHeading}>
          HEDGE Arbitrage broadens the HEDGE ecosystem with its unique blend of
          stability and opportunity within the crypto space.
        </h3>
        <div className={styles.howItWorksWrapper}>
          <p className={styles.howItWorksText}>
            Just like HEDGE Compass, HEDGE Arbitrage tokens are acquired by
            exchanging USD stablecoins, such as USD Tether, ensuring a secure
            point of entry into the ecosystem.
            <br />
            <br /> When individuals purchase HEDGE Arbitrage tokens, all funds
            generated are held in a dedicated reserve hosted by our treasury.
            The treasury then dynamically allocates these reserved funds into a
            diverse range of “arbitrage” hedge funds, each selected for their
            potential to optimize returns.
            <br />
            {isMobile && (
              <img
                src='/graphic-mob-arbitrage.svg'
                style={{ margin: '48px 0' }}
                alt='floor price mob'
              />
            )}
            <br /> Following the HEDGE model, HEDGE Arbitrage tokens can be
            redeemed for USD stablecoins. The floor price, the value at which HEDGE
            Arbitrage tokens can be bought and redeemed, is determined by the value 
            of the reserve and the number of HEDGE Arbitrage tokens in circulation. 
            As the value of the reserve changes over time depending on the performance 
            of the hedge funds, so will the floor price.
          </p>
          {!isMobile && (
            <img src='/graphic-desc-arbitrage.svg' alt='floor price desc' />
          )}
        </div>
        <div
          style={{
            display: isMobile ? 'flex' : 'block',
            justifyContent: isMobile ? 'center' : 'initial',
            marginBottom: isMobile ? '62px' : '97px',
          }}>
          <img
            src={isMobile ? '/Arbitrage-mob.svg' : '/Arbitrage-desk.svg'}
            alt='Arbitrage compass'
          />
        </div>
        <div>
          <h3 className={styles.bottomHeading}>
            What is an “arbitrage” Hedge Fund?
          </h3>
          <h4 className={styles.bottomSubHeading}>
            Like all hedge funds, arbitrage hedge funds are only available for
            the select few individuals and corporations with enough financial
            resources and technical knowledge to meet the strict entry criteria.
          </h4>
          <p className={styles.bottomText}>
            These funds are able to use strategies and market trading techniques
            not typically available to normal investment funds. Most notably,
            “arbitrage” hedge funds are able to profit from price differentials
            of particular assets or commodities in different markets and
            countries. For example, gold futures could be trading at a different
            price in Tokyo than in London. An arbitrage hedge fund would then
            exploit that price difference by purchasing gold futures in the
            market where it is cheaper and selling it where they are trading at
            a higher price. The price difference may be caused by fluctuating
            currencies or simple differences in the supply and demand for the
            commodity or asset in different parts of the globe.
          </p>
        </div>
      </div>
    </section>
  );
};
export default Arbitrage;